import React, { useState, useEffect, useCallback } from "react";
import "./Footer.css";
import DGHS from "/branding/DGHS logo.png";
import EPI from "/branding/EPI logo.png";
import MIS from "/branding/MIS logo.png";
import UNICEF from "/branding/unicef logo.svg";
import STATWIG from "/branding/statwig logo.svg";

export default function Footer() {
  const [isNewVersion, setIsNewVersion] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(
    import.meta.env.VITE_APP_VERSION ?? "NA",
  );

  // Function to extract version from HTML
  const extractVersionFromHTML = useCallback((html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    return doc.querySelector('meta[name="version"]')?.content || "1.0.0";
  }, []);

  // Initial version setup from cached HTML
  useEffect(() => {
    const cachedHTML = document.documentElement.outerHTML;
    const cachedVersion = extractVersionFromHTML(cachedHTML);
    setCurrentVersion(cachedVersion);
  }, [extractVersionFromHTML]);

  // Function to check for the new version by fetching the root HTML
  const checkVersion = useCallback(() => {
    fetch(window.location.origin, { cache: "no-store" })
      .then((response) => response.text())
      .then((html) => {
        const newVersion = extractVersionFromHTML(html);
        if (newVersion && newVersion !== currentVersion) {
          setIsNewVersion(true);
        }
      })
      .catch((error) => console.error("Failed to fetch version:", error));
  }, [currentVersion, extractVersionFromHTML]);

  // Set up an interval to periodically check for a new version
  useEffect(() => {
    const interval = setInterval(() => {
      if (navigator.onLine) {
        checkVersion();
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [checkVersion]);

  // Handle reload by clearing caches and reloading the page
  const handleReload = () => {
    if (navigator.onLine) {
      caches
        .keys()
        .then((cacheNames) =>
          Promise.all(cacheNames.map((cacheName) => caches.delete(cacheName))),
        )
        .then(() => {
          window.location.reload(true);
        });
    } else {
      alert("You're offline. Please connect to the internet to update");
    }
  };

  return (
    <footer className='Footer__container'>
      <figure className='Footer__wrapper'>
        <div className='Footer__support_logo'>
          <img src={DGHS} alt='dghs logo' />
          <img src={EPI} alt='epi logo' />
          <img src={MIS} alt='mis logo' />
        </div>
        <div>
          <p className='page__note_fs'>
            All Rights Reserved by MIS, DGHS, MOH & FW
          </p>
        </div>
        <div className='powered_by_logo'>
          <div className='powered_wrapper'>
            <p className='page__note_fs'>Powered By</p>
            <div className='powered_logo'>
              <img src={STATWIG} alt='statwig logo' />
            </div>
          </div>
          <p className='page__tiny_fs'>
            Version {currentVersion}
            {isNewVersion &&
              " - A new version of the app exists. Please reload the page to update."}
            {isNewVersion && (
              <span
                style={{ cursor: "pointer", color: "#007bff" }}
                onClick={handleReload}
              >
                Click here
              </span>
            )}
          </p>
        </div>
        <div className='Footer__sponsor_logo'>
          <img src={UNICEF} alt='unicef logo' />
        </div>
      </figure>
    </footer>
  );
}
